@keyframes slide {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}

.TecTitle {
  margin: 0 2rem;
  font-size: 3.5rem;
  letter-spacing: 0.7rem;
}

.logos {
  margin: 15px 2.1%;
  overflow: hidden;
  padding: 15px 0;
  background: white;
  white-space: nowrap;
  position: relative;
}

.logos:before,
.logos:after {
  position: absolute;
  top: 0;
  width: 250px;
  height: 100%;
  content: "";
  z-index: 2;
}

.logos:before {
  left: 0;
  background: linear-gradient(to left, rgba(255, 255, 255, 0), white);
}

.logos:after {
  right: 0;
  background: linear-gradient(to right, rgba(255, 255, 255, 0), white);
}

/* .logos:hover .logos-slide {
  animation-play-state: paused;
} */

.logos-slide {
  display: inline-block;
  height: 7rem;
  animation: 10s slide infinite linear;
}

.logos-slide img {
  height: 100%;
  margin: 0 100px;
}

@media (max-width: 601px) {
  .TecTitle {
    font-size: 1.5rem;
    margin: 2rem 1rem;
  }

  .logos:before {
    background: linear-gradient(
      to left,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0.5)
    );
  }

  .logos:after {
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0.5)
    );
  }

  .logos-slide {
    animation: 7s slide infinite linear;
  }
}
