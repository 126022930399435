.InfoMain {
  height: 100%;
  display: flex;
  align-items: center;
}

.InfoTitle {
  margin: 0 2rem;
  font-size: 4.5rem;
  letter-spacing: 2rem;
}

.InfoText {
  font-size: 26px;
  margin: 5rem 2rem;
}

.InfoDiv {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.InfoImgDiv {
  margin: auto;
  width: 40rem;
  display: flex;
  justify-content: flex-end;
}
.InfoImgDiv img {
  width: 30rem;
  height: 80%;
}

@media (max-width: 601px) {
  .InfoMain {
    display: flex;
    flex-direction: column;
  }
  .InfoText {
    font-size: 15px;
    text-align: center;
    margin: 2rem -3rem;
  }
  .InfoImgDiv {
    width: 80%;
  }
  .InfoImgDiv img {
    width: 100%;
    height: 95%;
  }
  .InfoDiv {
    align-items: center;
  }
  .InfoTitle {
    font-size: 2rem;
    text-align: center;
    letter-spacing: 10px;
    margin: 1rem 0;
  }
}
