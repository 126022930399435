.DivSelect {
  position: absolute;
  top: 10px;
  right: 10px;
  margin: 1rem;
  color: black;
}
.menu-flags button {
  color: aliceblue;
  background-color: black;
  border-color: aliceblue;
}
