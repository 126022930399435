body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #0f0f0f;
  color: white;
  font-family: "Roboto", sans-serif;
}
body::-webkit-scrollbar {
  width: 6px;
}

body::-webkit-scrollbar-track {
  background-color: transparent;
}

body::-webkit-scrollbar-thumb {
  background-color: rgba(207, 207, 207, 0.2);
  border-radius: 3px;
}

body::-webkit-scrollbar-thumb:hover {
  background-color: rgba(151, 151, 151, 0.4);
}

body::-webkit-scrollbar-thumb:horizontal {
  min-width: 30px;
}

body::-webkit-scrollbar-thumb:vertical {
  min-height: 30px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
