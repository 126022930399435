.HomeMain {
  height: 40rem;
  margin: 0.777%;
  padding: 1%;
  border: 1px solid white;
}

.DivLogo {
  position: absolute;
  top: 12%;
  left: 10%;
}

.H1Logo {
  letter-spacing: 0.25rem;
}

@media (max-width: 601px) {
  .HomeMain {
    width: 99%;
    height: 50rem;
    padding: 0;
    margin: 0;
    margin-top: 0.1rem;
  }
  .DivLogo img {
    width: 70%;
  }
  .H1Logo {
    font-size: 1rem;
    letter-spacing: 0;
  }
}
